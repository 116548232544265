@import '~scss/breakpoints';
@import '~scss/colors';

.notFound {
  display: flex;
  flex-direction: column;

  h1 {
    text-align: center;
  }

  button {
    margin: 0 auto;
  }
}
