@import '~scss/colors';

.footer {
  font-size: calc(10px);
  min-height: 0;

  div {
    background-color: $white-transparent;
    padding: 0.5em 1em;
  }
}
