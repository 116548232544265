$white: #f4f4f4;
$white-transparent: rgba(244, 244, 244, 0.85);
$green: #b5ca8d;
$black: #2a2d34;
$tan: #b39980;

// $green-border: #6a823d;

$green-hover: #a6bf76;
$white-hover: #e3e3e3;
