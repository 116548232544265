@import '~scss/breakpoints';
@import '~scss/colors';

.parallax {
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  overflow: auto;

  @media (min-width: $sm) {
    background-attachment: fixed;
  }
}

.opacity {
  height: 100%;

  overflow: auto;
}
