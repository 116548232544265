.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 4rem;

  ul {
    display: flex;
    list-style-type: none;
    padding: 0;

    li {
      font-size: 1.25rem;
      transition: 0.3s;

      &:hover {
        // transform: scale(1.25, 1.25);
        transform: translateY(-7px);
      }

      & + li {
        margin-left: 2rem;
      }

      a {
        cursor: pointer;
        padding: 1rem;
      }

      a:hover {
        color: #000000;
        text-decoration: none;
      }
    }
  }
}
