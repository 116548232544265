@import '~scss/breakpoints';
@import '~scss/colors';

.contentSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 2rem;

  @media (min-width: $lg) {
    flex-direction: row;
    margin: 4rem auto;
    max-width: 60%;
  }

  .imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    @media (min-width: $lg) {
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    h2 {
      font-size: 2.5rem;
      font-weight: normal;
      margin: 0 0 2rem 0;
      text-align: center;
    }

    p {
      font-size: 1.25rem;
      line-height: 2;
      margin-top: 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
