@import '~scss/breakpoints';
@import '~scss/colors';

.root {
  background: inherit;
  border: 0;
  // height: 48px;
  // width: 48px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    transform: translateY(-7px);
  }
}
