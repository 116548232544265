@import '~scss/colors';

.root {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 4rem;
  width: 100%;

  .overlay {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 2;

    .close {
      align-self: flex-end;
      margin: 1rem;
    }

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 50px;
      color: $black;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);

      ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        padding: 0;

        li {
          font-size: 1.25rem;
          transition: 0.3s;

          &:hover {
            transform: translateY(-7px);
          }

          & + li {
            margin-top: 2rem;
          }

          a {
            color: $black;
            cursor: pointer;
          }

          a:hover {
            color: #000000;
            text-decoration: none;
          }
        }
      }
    }
  }
}
